<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            Student Programme / Groups
            <v-spacer></v-spacer>
            <add-button
              permission="grade-create"
              @action="
                (form.dialog = true), $refs.form ? $refs.form.reset() : ''
              "
              >New Student Group
            </add-button>
          </v-card-title>
           <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
            :footer-props="footerProps"

          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-left">{{ item.name }}</td>
                <td class="text-right">
                  <!--                                    <view-button permission="section-read" @click.native="viewDepartment(item.id)"/>-->
                  <edit-button
                    v-if="item.id !== 'ungrouped'"
                    :permission="true"
                    @agree="form.edit(item)"
                  />
                  <delete-button
                    v-if="item.id !== 'ungrouped'"
                    :permission="true"
                    @agree="form.delete(item.id)"
                  />
                  <view-button 
                    :permission="true"
                    @action="viewDetails(item)"
                  />
                  <!-- <view -->
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" persistent max-width="400px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <v-form
              ref="form"
              v-model="valid"
              :lazy-validation="lazy"
              @submit.prevent="save"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
            >
              <v-text-field
                autocomplete="off"
                label="Name*"
                required
                class="pa-0"
                outlined
                dense
                v-model="form.name"
                name="name"
                :error-messages="form.errors.get('name')"
                :rules="[(v) => !!v || 'Name is required']"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text @click="form.dialog = false"
              >Close</v-btn
            >
            <v-btn color="success" text @click="validate">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
export default {
  mixins: [Mixins],
  data: () => ({
    valid: true,
    lazy: false,
    isLoading: false,
    form: new Form(
      {
        name: "",
      },
      "/api/student-group"
    ),
    headers: [
      { text: "#", align: "left", value: "id", width: 50, sortable: false },
      { text: "Name", align: "left", value: "name", sortable: false },
      { text: "Action", align: "right", sortable: false, width: 200 },
    ],
  }),
  watch: {
    pagination: function () {
      this.get();
    },
  },
  methods:{
     validate() {
      if (this.$refs.form.validate()) {
        this.form.store();
      }
    },
     get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.form.items.data.push({name: 'Ungrouped', 'id': 'ungrouped'})
        this.pagination.totalItems = data.meta.total;
      });
    },
    viewDetails(record){
      console.log(record)
      this.$router.push({
        name: "student group details",
        params: {
          group_id: record.id,
        },
        query: {
          name: record.name
        }
      });
    }
  }
};
</script>
